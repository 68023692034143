export const __BUS = {
  mutateTableAgain: "MUTATE_TABLE_AGAIN",
  openMainSideBar: "OPEN_MAIN_SIDE_BAR",
  closeMainSideBar: "CLOSE_MAIN_SIDE_BAR",
  closePrompt: "CLOSE_PROMPT",
  stopMyScreenSharing: "STOP_MY_SCREEN_SHARING",
  someoneStartTalking: "SOMEONE_START_TALKING",
  someoneStopTalking: "SOMEONE_STOP_TALKING",
  scrollEndChatBox: "SCROLL_END_CHATBOX",
  scrollMessageToEnd: "SCROLL_MSG_TO_END",
  backToOriginMessage: "BACK_TO_ORIGIN_MESSAGE",
  scrollToTopNewChatMessages: "SCROLL_TO_TOP_NEW_CHAT_MESSAGES",
  scrollToTargetMessage: "SCROLL_TO_TARGET_MESSAGE",
  changeMyUserCoord: "CHANGE_MY_USER_COORD",
  userLeftRoom: "USER_LEFT_ROOM",
  socketConnected: "SOCKET_CONNECTED",
  socketDisConnected: "SOCKET_DISCONNECTED",
  rejoinMeet: "REJOIN_MEET",
  rejoinRoom: "REJOIN_ROOM",
  startWorkTimer: "START_WORK_TIMER",
  stopWorkTimer: "STOP_WORK_TIMER",
  showAddChat: "SHOW_ADD_CHAT",
  hideAddChat: "HIDE_ADD_CHAT",
  selectChat: "SELECT_CHAT",
};
